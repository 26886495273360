.App {
  text-align: center;
  height: 100vh;
}

.App-container {
  width: 80%;
  margin: auto;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
