.ProjectCard {
  width: 17%;
  height: 320px;
  width: 250px;
  box-shadow: 0 3px 10px #00000033;
  border-radius: 12px;

  display: flex !important;
  justify-content: space-between;
  margin: 20px 30px;
}

.ProjectCard:hover {
  border: 2px solid black;
}

.ProjectCard-circle-benefit {
  border-radius: 12px;
}

.ProjectCard-circle-benefit img {
  width: 250px;
  height: 250px;
  padding: 10px;

  border-radius: 20px;
}

.ProjectCard a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  position: relative;
}

.ProjectCard-opensea {
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  right: 20px;
  top: 200px;
  padding: 5px 10px;
  border-radius: 30px;
}
.ProjectCard-opensea img {
  height: 20px;
  margin-top: -3px;
}