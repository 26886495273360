.YourProjects h2 {
  text-align: left;
  padding: 20px 0;
}

.YourProjects a {
  text-decoration: none;
}

.YourProjects-list {
  display: flex;
  flex-wrap: wrap;
  padding-inline-start: 0px;
  justify-content: center;
}