.ProjectDetails {
  position: relative;
}

.ProjectDetails h1 {
  font-size: 24px;
}

.ProjectDetails h2 {
  text-align: left;
  padding: 20px 0;
  color: #0F2D4F;
}

.ProjectDetails-Back {
  position: absolute;
  left: -50px;
  top: 75px;
  cursor: pointer;
}

.ProjectDetails-Item {
  width: 45%;
  text-align: left;
  margin: 20px 0;
  border-radius: 8px;
  border: 1px solid #5A2A9B;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  font-weight: 600;
  gap: 10px;
}

.ProjectDetails-Item-source {
  display: flex;
  justify-content: flex-end;
}

.ProjectDetails-descriptionCard {
  display: flex;
  text-align: left;
  width: 65%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 10px;
  height: 220px;
}

.ProjectDetails-descriptionCard img {
  padding-right: 20px;
}

.ProjectDetails-descriptionCard h1 {
  font-size: 25px;
}

.ProjectDetails-descriptionCard h5 {
  font-size: 15px;
}

.ProjectDetails-descriptionCard img {
  padding-right: 20px;
}

.ProjectDetails-updates {
  width: 25%;
  text-align: left;
}

.ProjectDetails-description {
  overflow: auto;
  max-height: 120px;
}