.Header {
  width: 100%;
  background-color: #0F2D4F;
  color: #0C1618;
  display: flex;
  flex-direction: row-reverse;
  padding: 20px 40px;
}

.Header .btn-success {
  margin-right: 30px;
}

.Header img {
  width: 50px;
}