.MailChimpSub-form  button {
  border-radius: 4px;
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #198754;
  color: white;
  border: none;
}

.MailChimpSub-form  input {
  border-radius: 4px;
  margin-left: 20px;
  padding-left: 5px;
  width: 250px;
  height: 35px;
}

.MailChimpSub {
  position: absolute;
  width: 100%;
  padding: 10px 10px 30px;
  border: 2px solid #5A2A9B;
  border-radius: 20px;
}